import {AbstractComponent, ComponentLoader} from "ui-base/src/AbstractComponent";

declare const $: any;

export class PasswordRevealComponent extends AbstractComponent {

    public static selector: string = 'password-reveal';

    public init()
    {
        this.setupClickHandlers();
        if(this.getComponentElement().attr('password-reveal-on-page-load'))
        {
            this.togglePasswordReveal(true)
        }
    }

    private setupClickHandlers()
    {
        $('[password-reveal-trigger]').on('click', () => {
            this.togglePasswordReveal();
        });
    }

    private togglePasswordReveal(onLoad = false)
    {
        // Find input
        let input = this.getComponentElement().find('input');

        // get type
        let type = input.attr('type') == "password" ? "text" : "password";

        // update input
        input.attr('type', type);

        if(!onLoad)
        {
            input.trigger('focus');
        }


        let icon = this.getComponentElement().find('[password-reveal-trigger]');

        icon.hasClass('input__password-reveal-icon--active')
            ?
                icon.removeClass('input__password-reveal-icon--active')
            :
                icon.addClass('input__password-reveal-icon--active')

    }

}

new ComponentLoader(PasswordRevealComponent);
